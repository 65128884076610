import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { getLayout } from 'components/Layout';
import useCurrentUser from 'libs/useCurrentUser';
import { isAgencyAdmin } from 'utils/roles';

function Index() {
  const { user } = useCurrentUser();

  const router = useRouter();

  useEffect(() => {
    if (!user) {
      router.replace('/login');
    } else if (isAgencyAdmin(user.role)) {
      router.replace('/opportunities');
    } else {
      router.replace('/projects');
    }
  }, [user, router]);

  return null;
}

Index.getLayout = (page) => getLayout(page, '', { hideHeader: true });

export default Index;
